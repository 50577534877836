<template>
  <main>
    <trac-loading v-if="isLoading" />
    <trac-modal class=" z-50" v-if="invalidOtpModal" :cancel="false">
      <div class="flex flex-col items-center justify-center mx-auto py-6 w-2/3 px-12 gap-y-4">
        <img class="w-16 h-16" src="@/assets/svg/wrong-otp.svg" alt="warning" />
        <p class="text-center text-xs">
          Wrong OTP Entered
        </p>
        <div class="flex-1"></div>
        <button @click="switchToModal('showOtpModal')" class="uppercase text-sm font-semibold my-4">Ok</button>
      </div>
    </trac-modal>
    <trac-modal v-if="showOtpModal" @close="showOtpModal = false">
      <div class="flex flex-col items-center justify-center px-12 gap-y-4">
        <img src="@/assets/svg/warning_otp.svg" alt="warning" />
        <h2 class="text-lg font-semibold">A New Device has been detected!</h2>
        <p class="text-center text-sm">
          We sense that you are logging into your Traction account from a new device we don’t recognise. To help verify that this is you, we have sent an authentication code to your email address and phone number.
        </p>
        <p class="text-center text-sm">
          Kindly enter the code sent below to confirm and continue with your session
        </p>
        <trac-otp-input @otp-filled="assignOTP" />
        <trac-button :disabled="!otpIsValid" @button-clicked="authenticateOTP" class="uppercase">Verify</trac-button>
        <button @click="logUserIn()" class="uppercase smallest font-semibold my-2">Resend OTP</button>
      </div>
    </trac-modal>
    <div class="flex w-full justify-center mt-16 items-center">
      <div ref="plac" class="
          max-w-4xl
          auth-shadow
          p-5
          flex-col
          pb-16
          rounded-md
          w-full
          flex
          items-center
          justify-center
        ">
        <div class="flex items-center justify-center flex-col mt-12 mb-6">
          <img src="@/assets/images/traction-logo.png" class="w-48" alt="" />
          <!-- <h2 class="text-sm pt-2 font-medium text-primaryBlue tracking-wide">
            traction apps
          </h2> -->
        </div>
        <trac-validation-observer slim v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(logUserIn)" class="max-w-xl ma w-full mt-12">
            <h1 class="text-lg font-medium text-accentDark">Sign in</h1>
            <trac-validation-provider name="email" rules="required|email" v-slot="{ errors }">
              <trac-input v-model.trim="loginDetails.email" placeholder="Email Address" class="mt-5" ref="emails"
                data-test="email-field" type="email" tag="div" />
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>
            <trac-validation-provider name="password" rules="required" v-slot="{ errors }">
              <div>
                <trac-input v-model.trim="loginDetails.password" type="password" placeholder="Password"
                  specified="password" data-test="password-field" class="mt-6" ref="password" tag="div" />
              </div>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>
            <div class="mt-4 flex items-center">
              <trac-checkbox  data-test="remember-me" />
              <p class="text-xs text-primaryGray pl-2">Remember me</p>
            </div>
            <div class="mt-8 flex items-center justify-center flex-col">
              <trac-button buttonType="submit" data-test="login-btn" class="w-full max-w-xs">
                <div class="py-1">Login</div>
              </trac-button>
              <p class="text-xs mt-4">
                Don’t have an account?
                <router-link data-test="create-one" to="/signup" class="text-primaryBlue font-medium">Create
                  one.</router-link>
              </p>
              <!-- <router-link
                to="/forgot"
                class="mt-3 text-xs text-primaryBlue font-medium"
                >Forgot password?</router-link
              > -->
              <router-link data-test="forgot-passwrod" to="/forgot-password"
                class="mt-3 text-xs text-primaryBlue font-medium">Forgot password?
              </router-link>
            </div>
          </form>
        </trac-validation-observer>
      </div>
    </div>
    <p class="text-xs text-gray-600 mx-auto w-1/2 text-center leading-5 my-6">
    Traction Payments Limited is licensed by the Central Bank of Nigeria as a Payment Service Provider with PSSP, PTSP, & Super Agent authorisation. <br>
    TA Prime Limited is a licensed money lender
    </p>
  </main>
</template>

<script>
import prepDb from '../offline-module/lb-helper'
import WLMixin from '../../src/security-module/WhiteListingMixin'
import {
  ADD_AUTH_USER,
  GET_USER_TOKEN,
  GET_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../browser-db-config/localStorage";
import { FETCH_ALL_DATA } from "../browser-db-config/utilityMethods";
import { eventBus } from "./../main";
import { SAVE_OBJECT_STORE } from '../browser-db-config/indexedDB';

export default {
  mixins: [WLMixin],
  data() {
    return {
      isLoading: false,
      loginDetails: {
        email: "",
        password: "",
      },
      invalidOtpModal: false,
      authUserData: {
        userData: null,
        businessData: null,
        tokenTimeStamp: null,
        secondaryTokenData: null,
      }
    };
  },
  mounted() {
    if (this.$route.params) {
      this.loginDetails.email = this.$route.params.email;
    }
  },
  methods: {
    switchToModal(which) {
      if(which === 'invalidOtpModal') {
        this.invalidOtpModal = true
        this.showOtpModal = false
      }
      if(which === 'showOtpModal') {
        this.invalidOtpModal = false
        this.showOtpModal = true
      }
    },
    assignOTP(otp) {
      this.otp = otp;
    },
    async authenticateOTP() {
      this.isLoading = true
      const payload = await this.generateDeviceDetails({ email: this.loginDetails.email, otp: this.otp })
      const res = await this.$store.dispatch("AUTHENTICATE_OTP", payload);
      // this.isLoading = false
      if (res.status) {
        this.showOtpModal = false
        this.logUserIn()
      } else {
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Network error occured.",
        });
        this.switchToModal('invalidOtpModal')
        this.isLoading = false;
      }
    },
    async logUserIn() {
      // Clear all data on localStorage
      // localStorage.clear();

      this.isLoading = true;
      await this.$store.dispatch("LOG_USER_IN", {...this.loginDetails, deviceID: this.WLdeviceId});
      const res = this.$store.getters["GET_LOGIN_RES"];

      const continueScript = this.actBasedOnResponseCode(res)
      this.isLoading = continueScript

      if (continueScript) {
        await this.continueProcessToDashboard(res)
      }

    },
    async continueProcessToDashboard(res) {
      if (res.status) {
        // console.log('MixPanel Instance Reset. New Id is: ', this.$MixpanelGetDistinctId());
        // await CLEAR_ALL_DATA_IN_DB();
        // debugger
        this.saveData("traction-app-user-data", res.data);

        this.$MixpanelReset(); // Reset Mixpanel
        this.$SetIdentity(res.data.user._id)

        // Save the login response
        this.authUserData.userData = res.data;

        await this.fetchBusinessUserDetails().then((data) => {
          // debugger
          this.$SetPerson(res.data.user)
          this.$MixPanelRegister(res.data.user)
          this.$intercom.boot({
            user_id: data.user_id,
            name: data.name,
            email: data.email,
          });
          this.$router.push({ name: "Dashboard" });
        });

        // Save data to IndexedDB so as to be used in ServiceWorker
        // const authResDBData = await CONNECT_TO_DB(
        //   "auth_data_db",
        //   1,
        //   "auth_data"
        // );
        // await ADD_DATA_TO_DB(authResDBData, "auth_data", res.data);
        await SAVE_OBJECT_STORE("auth_data_db", "auth_data", res.data);

        // const offlineProductsDB = await CONNECT_TO_DB('offline_db_products', 1, 'products');
      } else {
        // alert(res.message || "Error: Network error occured.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Network error occured.",
        });
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    fetchLoyaltyPointToken() {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json;charset=utf-8");
      myHeaders.append("Authorization", "Bearer " + GET_USER_TOKEN());

      // Fetch Token
      fetch(`https://tractionapp-stage.herokuapp.com/auth/loyalty/access`, {
        method: "POST",
        headers: myHeaders,
      })
        .then((res) => res.json())
        .then(async (data) => {
          // Add secondary data for perssistent auth data
          this.authUserData.secondaryTokenData = data;

          this.$store.dispatch("DOWNLOAD_CUSTOMERS_POINTS", data);
          // FETCH_ALL_DATA();
          // await SAVE_OBJECT_STORE(
          //   "secondary_token_db",
          //   "secondary_token",
          //   data.data
          // );

          // Check is store credit for buiness is active
          this.$store.dispatch("CHECK_BUSINESS_STORE_CREDIT_IS_ACTIVE");

          // Add auth user to list of auth users
          ADD_AUTH_USER(this.authUserData);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchBusinessUserDetails() {
      console.log("Business");
      await this.$store.dispatch("FETCH_BUSINESS_USER_DETAILS");
      const res = this.$store.getters["GET_BUSINESS_USER_RES"];

      if (res.status) {
        this.saveData("traction-app-current-user-business-data", res.data);
        this.authUserData.businessData = res.data;

        // Add token for  perssistent login
        this.authUserData.tokenTimeStamp = GET_LOCAL_DB_DATA("auth-token-date");

        this.fetchLoyaltyPointToken();

        // Add auth user to list of auth users
        ADD_AUTH_USER(this.authUserData);

        // Save data to IndexedDB so as to be used in ServiceWorker
        // const businessResDBData = await CONNECT_TO_DB(
        //   "business_data_db",
        //   1,
        //   "business_data"
        // );
        // await ADD_DATA_TO_DB(businessResDBData, "business_data", res.data);
        await SAVE_OBJECT_STORE("business_data_db", "business_data", res.data);

        // this.fetchAllAssets();

        const allKeysTest = await prepDb(res.data._id)
        console.log('allKeysTest: ', allKeysTest)

        this.clearAllPeriodicSyncInstructions()

        // debugger
        this.$InstantiateGlobalOfflineManager(res.data._id)

        console.log('timeoutList upon logging in: ', this.$timeoutList)

        // Start Periodic Sync after successfully logging in
        const delayBeforePeriodicSync = setTimeout(() => {
          this.$beginPeriodicSync()
          console.log('Now firing this.$beginPeriodicSync() after set delay ')
          clearTimeout(delayBeforePeriodicSync)
        }, 11000);
        // this.$requestMonitoring() // starts the periodic monitoring of everything that happens in SW
        // this.$beginPeriodicSync()

        return res.data

        // this.$router.push({ name: "Payments" });
      } else {
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Network error occured.",
        });
        this.isLoading = false;
        return null
      }
    },
    saveData(localStorageName, dataToStore) {
      SAVE_LOCAL_DB_DATA(localStorageName, dataToStore);
    },
    fetchAllAssets() {
      // this.$store.dispatch("FETCH_ALL_CUSTOMERS");
      // this.$store.dispatch('FETCH_ALL_CUSTOMERS');
    },
  },
};
</script>

<style></style>
